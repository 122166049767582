import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

const state = {
  cancellableSearchRequest: null
};

export const SET_CANCELLABLE_SEARCH_REQUEST = 'SET_CANCELLABLE_SEARCH_REQUEST';

const mutations = {
  [SET_CANCELLABLE_SEARCH_REQUEST]: (state, payload) => {
    state.cancellableSearchRequest = payload;
  }
};

export default new Vuex.Store({
  modules,
  state,
  mutations
});
