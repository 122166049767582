import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_LOGIN_API_USERNAME,
  password: process.env.VUE_APP_LOGIN_API_PASSWORD
};

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const ORGANISATION_API_PATH = process.env.VUE_APP_ORGANISATION_API_PATH;
const USERGROUP_API_PATH = process.env.VUE_APP_USERGROUP_API_PATH;

const organisationsAPI = {

  async getOrganisationsTypes() {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisation-types/`), DOMAIN);
    let response;
    try {
      response = await axios.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  async getOrganisationsRoles() {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-group-roles/`), DOMAIN);
    let response;
    try {
      response = await axios.get(url);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  },

  async setOrganisationThumbnail(id, data) {
    const url = new URL(path.join(`${i18n.locale}${ORGANISATION_API_PATH}`, `organisations/${id}/thumbnail/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
};

export default organisationsAPI;
