<template>
  <div id="app">
    <LocaleChanger />
    <router-view id="page"/>
  </div>
</template>

<script>
import LocaleChanger from '@/components/LocaleChanger.vue';

export default {

  components: {
    LocaleChanger
  }

};
</script>
