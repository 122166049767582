<template>
  <div id="locale-changer">
    <select v-model="$i18n.locale">
      <option
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :value="lang"
      >
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>

export default {

  name: 'LocaleChanger',

  data () {
    return {
      langs: ['fr', 'en'],
    }
  },

  watch: {
    '$i18n.locale': function(newValue, oldValue) {
      if (newValue !== oldValue) {
        const to = this.$router.resolve({
          params: { locale: newValue },
          query: this.$route.query
        });
        this.$router.push(to.location);
      }
    }
  }

}
</script>
