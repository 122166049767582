import client from '@/api/loginAPI.js';
import organisationAPI from '@/api/organisationsAPI.js';
import axios from 'axios';

const state = {
  organisationsList: [],
  organisationsTypes: [],
  organisationsRoles: [],
};

const getters = { };

export const GET_ORGANISATIONS_LIST = 'GET_ORGANISATIONS_LIST';
export const GET_ORGANISATIONS_TYPES = 'GET_ORGANISATIONS_TYPES';
export const GET_ORGANISATIONS_ROLES = 'GET_ORGANISATIONS_ROLES';
export const SEARCH_ORGANISATIONS_LIST = 'SEARCH_ORGANISATIONS_LIST';

const actions = {
  [GET_ORGANISATIONS_LIST]: async ({ commit }) => {
    const organisations = await client.getOrganisationsList();
    commit('SET_ORGANISATIONS_LIST', organisations);
  },
  [SEARCH_ORGANISATIONS_LIST]: async ({ rootState, commit, dispatch }, text) => {

    if (text) {
      if (rootState.cancellableSearchRequest) {
        rootState.cancellableSearchRequest.cancel();
        commit('SET_CANCELLABLE_SEARCH_REQUEST', null, { root: true });
      }

      const cancelToken = axios.CancelToken.source();
      commit('SET_CANCELLABLE_SEARCH_REQUEST', cancelToken, { root: true });
      const url = `${process.env.VUE_APP_LOGIN_API}/organisations/?page=1&search=${text}`;

      try {
        const response = await axios.get(
          url,
          {
            cancelToken: cancelToken.token,
            ...process.env.NODE_ENV === 'development' && {
              auth: {
                username: process.env.VUE_APP_LOGIN_API_USERNAME,
                password: process.env.VUE_APP_LOGIN_API_PASSWORD
              }
            }
          }
        );
        if (response.status === 200) {
          commit('SET_CANCELLABLE_SEARCH_REQUEST', null, { root: true });
          const organisations = response.data;
          if (organisations) {
            commit('SET_ORGANISATIONS_LIST', organisations);
          }
        }
      } catch(err) {
        commit('SET_CANCELLABLE_SEARCH_REQUEST', null, { root: true });
      }
    } else {
      dispatch('GET_ORGANISATIONS_LIST', {
        direction: null,
        field: null
      });
    }
  },
  [GET_ORGANISATIONS_TYPES]: async ({ commit }) => {
    const types = await organisationAPI.getOrganisationsTypes();
    commit('SET_ORGANISATIONS_TYPES', types);
  },
  [GET_ORGANISATIONS_ROLES]: async ({ commit }) => {
    const roles = await organisationAPI.getOrganisationsRoles();
    commit('SET_ORGANISATIONS_ROLES', roles);
  }
};

export const SET_ORGANISATIONS_LIST = 'SET_ORGANISATIONS_LIST';
export const SET_ORGANISATIONS_TYPES = 'SET_ORGANISATIONS_TYPES';
export const SET_ORGANISATIONS_ROLES = 'SET_ORGANISATIONS_ROLES';

const mutations = {
  [SET_ORGANISATIONS_LIST]: (state, payload) => {
    state.organisationsList = payload ? payload.results ? payload.results : payload : [];
  },
  [SET_ORGANISATIONS_TYPES]: (state, payload) => {
    state.organisationsTypes = payload;
  },
  [SET_ORGANISATIONS_ROLES]: (state, payload) => {
    state.organisationsRoles = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
