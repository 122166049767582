import Vue from 'vue';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import Multiselect from 'vue-multiselect';

// Custom css
import '@/app.scss';
import '@/app.less';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('Multiselect', Multiselect);

import App from '@/App.vue';
import i18n from './i18n';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;

import config from '../public/config.js';

Vue.mixin({
  methods: {
    isFieldVisible(field, hiddenFields) {
      return !hiddenFields.includes(field);
    },
    isFieldRequired(field, requiredFields) {
      return requiredFields.includes(field) ? 'required' : '';
    }
  }
});

Vue.prototype.$config = config;
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
