import { ErrorService } from '@/services/error-service.js';
import usergroupsAPI from '@/api/usergroupsAPI';
import axios from 'axios';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;
const AUTH = {
  username: process.env.VUE_APP_LOGIN_API_USERNAME,
  password: process.env.VUE_APP_LOGIN_API_PASSWORD
};
const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const USERGROUP_API_PATH = process.env.VUE_APP_USERGROUP_API_PATH;

// MUTATIONS
export const SET_USERGROUPS_SPHERES_LIST = 'SET_USERGROUPS_SPHERES_LIST';
export const SET_ERROR = 'SET_ERROR';
export const SET_IS_SPHERES_SEARCHED = 'SET_IS_SPHERES_SEARCHED';

// ACTIONS
export const GET_SPHERES_LIST = 'GET_SPHERES_LIST';
export const SEARCH_SPHERES = 'SEARCH_SPHERES';

/**************** STATE *******************/
const state = {
  spheresCount: 0,
  spheres: [],
  usergroupsError: null,
  isSpheresSearched: false
};

/**************** GETTERS *****************/
const getters = {
};

/*************** MUTATIONS ****************/
const mutations = {

  [SET_USERGROUPS_SPHERES_LIST]: (state, payload) => {
    if (payload) {
      state.spheresCount = payload.count;
      state.spheres = payload.results;
    }
  },

  [SET_ERROR]: (state, error) => {
    if (error) {
      ErrorService.onError(error);
      state.usergroupsError = error;
    } else {
      state.usergroupsError = error;
    }
  },

  [SET_IS_SPHERES_SEARCHED]: (state, payload) => {
    state.isSpheresSearched = payload;
  }

};
/**************** ACTIONS *****************/
const actions = {

  [GET_SPHERES_LIST]: async ({ commit }) => {
    try {
      const spheres = await usergroupsAPI.getFilteredUsergroupsList()
      if (spheres) {
        commit('SET_ERROR', null);
        commit('SET_USERGROUPS_SPHERES_LIST', spheres);
      }
    } catch (error) {
      commit('SET_ERROR', error);
    }
  },

  [SEARCH_SPHERES]: async ({ rootState, commit }, text) => {
    if (rootState.cancellableSearchRequest) {
      rootState.cancellableSearchRequest.cancel();
      commit('SET_CANCELLABLE_SEARCH_REQUEST', null, { root: true });
    }

    const cancelToken = axios.CancelToken.source();
    commit('SET_CANCELLABLE_SEARCH_REQUEST', cancelToken, { root: true });
    const url = new URL(path.join(USERGROUP_API_PATH, `user-groups/?page=1&search=${text}&usergroup_types=group-of-organisation`), DOMAIN);

    try {
      const response = await axios.get(
        url,
        {
          cancelToken: cancelToken.token,
          ...DEV_AUTH && { auth: AUTH }
        }
      );
      if (response.status === 200) {
        commit('SET_CANCELLABLE_SEARCH_REQUEST', null, { root: true });
        const usergroups = response.data;
        if (usergroups) {
          commit('SET_ERROR', null);
          commit('SET_USERGROUPS_SPHERES_LIST', usergroups);
          commit('SET_IS_SPHERES_SEARCHED', true);
        }
      }
    } catch(err) {
      commit('SET_CANCELLABLE_SEARCH_REQUEST', null, { root: true });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
